import './AddAlertView.css';

const AddAlertView = () => {
    return (
        <div className={'add-alert-view'}>
            <h3>Formulario de alertas de viento</h3>
        </div>
    );
};

export default AddAlertView;