export const fromDegreesToCardinal = (degrees: number) => {
    if (degrees < 0 || degrees > 360) {
        throw new Error(`Invalid ordinal direction value ${degrees}`);
    }
    if (degrees === 0 || degrees === 360) {
        return 'N';
    }

    const quadrants = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'NW', 'NNW', 'N'];

    const directionIndex: string = Math.floor((degrees % 360) / 22.5).toFixed(0);
    return quadrants[parseInt(directionIndex)];
};
