import './AboutView.css';
import {Button, Container} from '@mui/material';
import {NavLink} from 'react-router-dom';

const AboutView = () => {

    return (
        <Container fixed className={'about-view'}>
            <div className={'header-title'}>
                Sistema de monitorización<br/> de condiciones en el Mediterraneo
            </div>

            <div className={'about-content'}>
                <p>Surfinity nace con la idea de que todo aquel que quiera disfrutar del mar,
                    ya sea remando sobre su tabla, cogido a su botavara, al pie del timon o con un remo
                    en la mano, esté más y mejor informado sobre las condiciones para la práctica de su
                    deporte favorito.</p>
                <p><b>No te pierdas una.</b></p>
                <p>Surfinity es una iniciativa 100% L3C (Emprendimiento social). Cualquier ayuda o colaboración será
                    bien
                    recibida.</p>
                <p>
                    Si tienes alguna sugerencia de mejora, por favor ponte en contacto <a
                    href="http://about.me/franserrano" rel="noreferrer" target="_blank">conmigo</a>, o a través de <a
                    href="https://www.facebook.com/pages/Surfinity/167143503362631" rel="noreferrer" target="_blank">Surfinity en
                    Facebook</a>.
                </p>
                <p>Gracias</p>
            </div>
            <NavLink to={'/home'}><Button href={'/home'}>Volver</Button></NavLink>
        </Container>
    );

};

export default AboutView;
