export const ErrorView = () => {
    return (
        <div>
            <p>Error view</p>
        </div>
    );
};




