import {useEffect, useState} from 'react';
import moment from 'moment';
import {ComposedChart, Legend, Line, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

import {fromDegreesToCardinal} from '../util/SurfinityUtils';
import ForecastDataRecord from '../domain/ForecastDataRecord';
import {ForecastDataService} from '../providers/forecast-data-service';

import './ForecastView.css';

const ForecastView = () => {

    const [forecastData, setForecastData] = useState<ForecastDataRecord[]>([]);

    useEffect(() => {
        ForecastDataService.findBySource(1).then(response => {
            setForecastData(response.data);
        });
    }, []);

    const _resolveEnergyClassName = (energy: number) => {
        if (energy < 150) return 'tooltip-low';
        if (energy >= 150 && energy < 200) return 'tooltip-medium';
        if (energy >= 200) return 'tooltip-high';
    };

    const _resolveWindSpeedClassName = (windSpeed: number) => {
        if (windSpeed < 12) return 'tooltip-low';
        if (windSpeed >= 12 && windSpeed < 16) return 'tooltip-medium';
        if (windSpeed >= 16) return 'tooltip-high';
    };

    const CustomTooltip = ({active, payload}) => {
        if (active && payload && payload.length) {
            return (
                <ul className={'tooltip-container'}>
                    <li className={_resolveWindSpeedClassName(payload[0].payload.windSpeed)}>{payload[0].payload.windSpeed}kts</li>
                    <li
                        className={'tooltip-direction'}>{payload[0].payload.windDirection}º {fromDegreesToCardinal(payload[0].payload.windDirection)}</li>
                    <li className={'tooltip-height'}>{payload[0].payload.waveHeight}m</li>
                    <li
                        className={'tooltip-direction'}>{payload[0].payload.waveDirection}º {fromDegreesToCardinal(payload[0].payload.waveDirection)}</li>
                    <li
                        className={_resolveEnergyClassName(payload[0].payload.waveEnergy)}>{payload[0].payload.waveEnergy}kj</li>
                    <li className={'tooltip-date'}><i>{moment(payload[0].payload.date).format('ddd HH:mm')}h</i></li>
                </ul>
            );
        }

        return null;
    };

    // @ts-ignore
    const _renderForecastData = () => {
        return (
            <ResponsiveContainer width="100%" height="80%">
                <ComposedChart data={forecastData} margin={{
                    top: 0,
                    right: 20,
                    left: -30,
                    bottom: 0,
                }}>
                    {/*<CartesianGrid strokeDasharray="3 5"/>*/}
                    <XAxis xAxisId={0} dataKey={'date'} tickFormatter={(date) => moment(date).format('ddd HH:mm')}/>
                    <XAxis xAxisId={1} dataKey={'windDirection'} orientation={'top'} stroke="#4268BB" tickFormatter={(direction) => fromDegreesToCardinal(direction)}/>
                    <XAxis xAxisId={2} dataKey={'waveDirection'} stroke="#48BB65" tickFormatter={(direction) => fromDegreesToCardinal(direction)}/>
                    <YAxis yAxisId={3} stroke="#4268BB"/>
                    <YAxis yAxisId={4} orientation={'right'} stroke="#48BB65"/>
                    <Tooltip content={<CustomTooltip/>}/>
                    <ReferenceLine yAxisId={3} y={14} stroke="#4268BB"/>
                    <ReferenceLine yAxisId={4} y={0.7} stroke="#48BB65"/>
                    <Line yAxisId={3} name={'Viento (kts)'} type="monotone" dataKey="windSpeed" stroke="#4268BB" strokeWidth={3} dot={false}/>
                    <Line yAxisId={4} name={'Ola (m)'} type="monotone" dataKey="waveHeight" stroke="#48BB65" dot={false} unit={'m'}/>
                    <Legend verticalAlign="bottom" height={10} align={'center'}/>
                </ComposedChart>
            </ResponsiveContainer>
        );
    };

    return (
        <div className={'wave-overview'}>
            {forecastData && _renderForecastData()}
        </div>
    );
};

export default ForecastView;