import './WindOverviewChart.css';
import {useEffect, useState} from 'react';
import {WindDataService} from '../providers/wind-data-service';
import WindDataRecord from '../domain/WindDataRecord';
import {Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

// @ts-ignore
import moment from 'moment';
import {FETCH_INTERVAL} from './DashboardView';

const WindOverviewChart = () => {
        const [windData, setWindData] = useState<WindDataRecord[]>([]);

        const fetchData = () => {
            WindDataService.findLastWindEntriesFromSource(1, 30).then((response) => {
                setWindData(response.data.reverse());
            }).catch(error => {
                console.log(error);
            });
        };

        useEffect(() => {
            fetchData();
            setInterval(fetchData, FETCH_INTERVAL);
        }, []);

        const CustomTooltip = ({active, payload}) => {
            if (active && payload && payload.length) {
                return (
                    <ul className={'tooltip-container'}>
                        <li className={'tooltip-wind-speed'}>{payload[0].value}kts</li>
                        <li className={'tooltip-direction'}>{payload[0].payload.windDirectionString}</li>
                        <li className={'tooltip-date'}><i>{moment(payload[0].payload.creationDate).format('HH:mm')}h</i>
                        </li>
                    </ul>
                );
            }

            return null;
        };

        const _renderWindData = () => {
            return (
                <ResponsiveContainer width={'100%'} height={'70%'}>
                    <LineChart data={windData}
                               margin={{
                                   top: 0,
                                   right: 20,
                                   left: -30,
                                   bottom: 10,
                               }}>
                        {/*<CartesianGrid strokeDasharray={'5'}/>*/}
                        <XAxis xAxisId={0} dataKey={'creationDate'} tickFormatter={(date) => moment(date).format('HH:mm')}/>
                        <XAxis xAxisId={1} dataKey={'windDirectionString'} orientation={'top'} stroke="#4268BB"/>
                        <YAxis dataKey={'windCurrentSpeed'} name={'Vel. actual'} stroke="#4268BB"/>
                        <Tooltip content={<CustomTooltip/>}/>
                        <ReferenceLine y={14} stroke="red"/>
                        <Line name={'Vel. actual (kts)'} type={'monotone'} dataKey={'windCurrentSpeed'}
                              stroke="#4268BB" fill="#3366CC"/>
                        <Legend verticalAlign={'bottom'} height={20} align={'center'}/>
                    </LineChart>
                </ResponsiveContainer>
            );
        };

        return (
            <div className={'wind-overview'}>
                {windData && windData.length > 0 && _renderWindData()}
            </div>
        );
    }
;

export default WindOverviewChart;
