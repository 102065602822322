import {BottomNavigation, BottomNavigationAction} from '@mui/material';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ListAltIcon from '@mui/icons-material/ListAlt';
import {useState} from 'react';
import WaveOverviewTable from './WaveOverviewTable';
import WaveOverviewChart from './WaveOverviewChart';

import './WaveOverview.css';

const WaveOverview = () => {

    const [selectedTab, setSelectedTab] = useState<number>(0);

    const handleTabChange = (event: React.SyntheticEvent, newSelection: number) => {
        setSelectedTab(newSelection);
    };

    const _renderChartMode = () => {
        return <WaveOverviewChart/>;
    };

    const _renderListMode = () => {
        return <WaveOverviewTable/>;
    };

    const _renderWaveData = (selectedTab: number) => {
        switch (selectedTab) {
            case 0: {
                return _renderChartMode();
            }
            case 1: {
                return _renderListMode();
            }
            default: {
                return _renderChartMode();
            }

        }
    };

    return (
        <div className={'wave-overview'}>
            <div className={'wave-data-container'}>
                {_renderWaveData(selectedTab)}
            </div>
            <BottomNavigation
                showLabels
                value={selectedTab}
                onChange={handleTabChange}
                sx={{position: 'fixed', bottom: 0, left: 0, right: 0}}
            >
                <BottomNavigationAction label="Gráfico" icon={<ShowChartIcon/>}/>
                <BottomNavigationAction label="Lista" icon={<ListAltIcon/>}/>
            </BottomNavigation>
        </div>
    );

};

export default WaveOverview;