import {fromDegreesToCardinal} from '../util/SurfinityUtils';
import {FormattedTime} from 'react-intl';

import './WaveDataRecordComponent.css';

interface WaveDataRecordProps {
    sourceName?: string;
    date: Date;
    height: number;
    direction: number;
    period: number;
    energy: number;
    creationDate?: Date;
}

const WaveDataRecordComponent = (props: WaveDataRecordProps) => {

    const _resolveEnergyClassname = () => {
        if (props.energy > 150 && props.energy < 200) return 'energy-low';
        if (props.energy >= 200) return 'energy-high';
    };

    return (
        <div className={'wave-data-record-component'}>
            <span className={'height-direction'}><b>{props.height || '--'}m {fromDegreesToCardinal(props.direction)} / {props.period || '--'}s</b></span>
            <span className={`${_resolveEnergyClassname()} energy`}><b>{props.energy}kj</b></span>
            <FormattedTime
                value={props.creationDate}
            />
        </div>
    );

};

export default WaveDataRecordComponent;
