import {AxiosResponse} from 'axios';
import WaveDataRecord from '../domain/WaveDataRecord';
import {API_BASE_URL} from '../constants/ApiConstants';
import ApiClient from '../ApiClient';

export class WaveDataService {

  static findWaveSummary(userId: number): Promise<AxiosResponse<WaveDataRecord[]>> {
    return ApiClient().get<WaveDataRecord[]>(`${API_BASE_URL}/wave/${userId}/summary`);
  }

  static findWaveList(): Promise<AxiosResponse<WaveDataRecord[]>> {
    return ApiClient().get(`${API_BASE_URL}/wave/last/all`);
  }

  static findCurrentWaveStatus(stationId: number): Promise<AxiosResponse<WaveDataRecord[]>> {
    return ApiClient().get(`${API_BASE_URL}/wave/${stationId}`);
  }

  static findLastWaveEntriesFromSource(stationId: number, maxResults: number = 20): Promise<AxiosResponse<WaveDataRecord[]>> {
    return ApiClient().get(`${API_BASE_URL}/wave/source/${stationId}/last`, {params: {maxResults}});
  }

  static findTopWaveList(): Promise<AxiosResponse<WaveDataRecord[]>> {
    return ApiClient().get(`${API_BASE_URL}/wave/top`);
  }

}
