import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createTheme, CssBaseline, ThemeProvider} from '@mui/material';

import moment from 'moment';

import './index.css';
import {IntlProvider} from 'react-intl';
import {API_BASE_URL} from './constants/ApiConstants';
import {HashRouter} from 'react-router-dom';

console.log('API base URL: ', API_BASE_URL);
console.log('Surfinity v' + process.env.REACT_APP_VERSION);
moment.locale('es', {
        months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
        monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
        weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
        weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
        weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
    }
);
console.log('Moment locale:', moment.locale());

const darkTheme = createTheme({
    palette: {
        mode: 'dark'
    }
});

ReactDOM.render(
    <React.StrictMode>
        <HashRouter basename={'/'}>
            <ThemeProvider theme={darkTheme}>
                <CssBaseline/>
                <IntlProvider locale={'es'}>
                    <App/>
                </IntlProvider>
            </ThemeProvider>
        </HashRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
