import {useState} from 'react';
import WindOverviewChart from './WindOverviewChart';
import WindOverviewTable from './WindOverviewTable';
import {BottomNavigation, BottomNavigationAction} from '@mui/material';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ListAltIcon from '@mui/icons-material/ListAlt';

export const WindOverview = () => {
    const [selectedTab, setSelectedTab] = useState<number>(0);

    const handleTabChange = (event: React.SyntheticEvent, newSelection: number) => {
        setSelectedTab(newSelection);
    };

    const _renderChartMode = () => {
        return <WindOverviewChart/>;
    };

    const _renderListMode = () => {
        return <WindOverviewTable/>;
    };

    const _renderWindData = (selectedTab: number) => {
        switch (selectedTab) {
            case 0: {
                return _renderChartMode();
            }
            case 1: {
                return _renderListMode();
            }
            default: {
                return _renderChartMode();
            }

        }
    };

    return (
        <div className={'wind-overview'}>
            <div className={'wind-data-container'}>
                {_renderWindData(selectedTab)}
            </div>
            <BottomNavigation
                showLabels
                value={selectedTab}
                onChange={handleTabChange}
                sx={{position: 'fixed', bottom: 0, left: 0, right: 0}}
            >
                <BottomNavigationAction label="Gráfico" icon={<ShowChartIcon/>}/>
                <BottomNavigationAction label="Lista" icon={<ListAltIcon/>}/>
            </BottomNavigation>
        </div>
    );
};