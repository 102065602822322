import {AxiosResponse} from 'axios';
import {API_BASE_URL} from '../constants/ApiConstants';
import ApiClient from '../ApiClient';
import ForecastDataRecord from '../domain/ForecastDataRecord';

export class ForecastDataService {

  static findBySource(sourceId: number): Promise<AxiosResponse<ForecastDataRecord[]>> {
    return ApiClient().get<ForecastDataRecord[]>(`${API_BASE_URL}/forecast/${sourceId}`);
  }

  static findWindToday(): Promise<AxiosResponse<ForecastDataRecord[]>> {
    return ApiClient().get<ForecastDataRecord[]>(`${API_BASE_URL}/forecast/wind-today`);
  }

}
