import './AlertChannelsView.css';
import {Button} from '@mui/material';
import {CameraAltSharp, Videocam} from '@mui/icons-material';

const BeachCamsView = () => {
    return (
        <div className={'beach-cams-view'}>
            <div className={'beach-cams-buttons-container'}>
                <Button fullWidth variant={'outlined'} color={'primary'} size={'large'}
                        startIcon={<Videocam/>} sx={{justifyContent: 'flex-start'}}>
                    <a href={'https://meteo365.es/livecams/valencia-live.php'} target={'_blank'} rel="noreferrer">Malvarrosa (Valencia)</a>
                </Button>
                <Button fullWidth variant={'outlined'} color={'primary'} size={'large'}
                        startIcon={<Videocam/>} sx={{justifyContent: 'flex-start'}}>
                    <a href={'https://www.camaramar.com/spots/patacona'} target={'_blank'} rel="noreferrer">Patacona (Valencia)</a>
                </Button>
                <Button fullWidth variant={'outlined'} color={'primary'} size={'large'}
                        startIcon={<Videocam/>} sx={{justifyContent: 'flex-start'}}>
                    <a href={'http://www.camaramar.com/spots/perellonet.html'} target={'_blank'} rel="noreferrer">Perellonet</a>
                </Button>
                <Button fullWidth variant={'outlined'} color={'primary'} size={'large'}
                        startIcon={<Videocam/>} sx={{justifyContent: 'flex-start'}}>
                    <a href={'http://www.camaramar.com/spots/gurugu'} target={'_blank'} rel="noreferrer">Club Eolo (Castellón)</a>
                </Button>
                <Button fullWidth variant={'outlined'} color={'primary'} size={'large'}
                        startIcon={<CameraAltSharp/>} sx={{justifyContent: 'flex-start'}}>
                    <a href={'https://www.meteogarcia.com/ww.html'} target={'_blank'} rel="noreferrer">Dossel (Cullera)</a>
                </Button>
            </div>
        </div>
    );
};

export default BeachCamsView;