import {AxiosResponse} from 'axios';
import WindDataRecord from '../domain/WindDataRecord';
import {API_BASE_URL} from '../constants/ApiConstants';
import ApiClient from '../ApiClient';

export class WindDataService {

    static findTopWindList(): Promise<AxiosResponse<WindDataRecord[]>> {
        return ApiClient().get(`${API_BASE_URL}/wind/top`);
    }

    static findWindSummary(userId: number): Promise<AxiosResponse<WindDataRecord[]>> {
        return ApiClient().get(`${API_BASE_URL}/wind/${userId}/summary`);
    }

    static findWindList(): Promise<AxiosResponse<WindDataRecord[]>> {
        return ApiClient().get(`${API_BASE_URL}/wind/all`);
    }

    static findLastWindEntriesFromSource(sourceId: number, maxResults: number): Promise<AxiosResponse<WindDataRecord[]>> {
        return ApiClient().get(`${API_BASE_URL}/wind/source/${sourceId}/last`, {params: {maxResults}});
    }

}
