import './WindOverviewTable.css';
import {Fragment, useEffect, useState} from 'react';
import WindDataRecord from '../domain/WindDataRecord';
import {WindDataService} from '../providers/wind-data-service';
import WindDataRecordComponent from '../components/WindDataRecordComponent';
import {Divider} from '@mui/material';
import {FETCH_INTERVAL} from './DashboardView';

const WindOverviewTable = () => {
    const [windData, setWindData] = useState<WindDataRecord[]>([]);

    const fetchData = () => {
        WindDataService.findLastWindEntriesFromSource(1, 50).then((response) => {
            setWindData(response.data);
        }).catch(error => {
            console.log(error);
        });
    };

    useEffect(() => {
        fetchData();
        setInterval(fetchData, FETCH_INTERVAL);
    }, []);

    const _renderWindData = () => {
        return windData.map((record: WindDataRecord) => {
            return (
                <Fragment key={record.id}>
                    <WindDataRecordComponent sourceFullname={record.sourceFullname} date={record.creationDate}
                                             windCurrentSpeed={record.windCurrentSpeed}
                                             direction={record.windDirectionString}/>
                    <Divider/>
                </Fragment>
            );
        });
    };

    return (
        <div className={'wind-overview'}>
            {_renderWindData()}
        </div>
    );
};

export default WindOverviewTable;
