import './WaveOverviewTable.css';
import {Fragment, useEffect, useState} from 'react';
import {WaveDataService} from '../providers/wave-data-service';
import WaveDataRecordComponent from '../components/WaveDataRecordComponent';
import WaveDataRecord from '../domain/WaveDataRecord';
import {Divider} from '@mui/material';
import {FETCH_INTERVAL} from './DashboardView';

const WaveOverviewTable = () => {
    const [waveData, setWaveData] = useState<WaveDataRecord[]>([]);

    const fetchData = () => {
        WaveDataService.findLastWaveEntriesFromSource(1, 50).then((response) => {
            setWaveData(response.data);
        }).catch(error => {
            console.log(error);
        });
    };

    useEffect(() => {
        fetchData();
        setInterval(fetchData, FETCH_INTERVAL);
    }, []);

    const _renderWaveData = () => {
        return waveData.map((record: WaveDataRecord) => {
            return (
                <Fragment key={record.id}>
                    <WaveDataRecordComponent  creationDate={record.creationDate} date={record.date}
                                             direction={record.direction} period={record.period} height={record.height}
                                             energy={record.energy}/>
                    <Divider/>
                </Fragment>
            );
        });
    };

    return (
        <div className={'wave-overview'}>
            {_renderWaveData()}
        </div>
    );
};

export default WaveOverviewTable;
