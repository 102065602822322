import {FormattedTime} from 'react-intl';

import './WindDataRecordComponent.css';

interface WindDataRecordProps {
    sourceFullname: string;
    date: Date;
    windCurrentSpeed: number;
    windAverageSpeed?: number;
    windGustSpeed?: number;
    direction: number | string;
}

const WindDataRecordComponent = (props: WindDataRecordProps) => {

    return (
        <div className={'wind-data-record-component'}>
            <span className={'speed-direction'}><b>{props.windCurrentSpeed || '--'} kts  - {props.direction}</b></span>
            <FormattedTime
                value={props.date}
            />
        </div>
    );

};

export default WindDataRecordComponent;
