export class LogService {

  static info(message: string, ...args: any[]): void {
    console.info(message, args);
  }

  static debug(message: string, ...args: any[]): void {
    console.debug(message, args);
  }

  static warn(message: string, ...args: any[]): void {
    console.warn(message, args);
  }

  static error(message: string, ...args: any[]): void {
    console.error(message, args);
  }

}
