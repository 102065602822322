import {useState} from 'react';
import WaveDataRecord from '../domain/WaveDataRecord';
import {fromDegreesToCardinal} from '../util/SurfinityUtils';
import WindDataRecord from '../domain/WindDataRecord';

export const WaveStation = () => {

    const [waveEntries, setWaveEntries] = useState<WaveDataRecord[]>([]);
    const [lastWindEntry, setLastWindEntry] = useState<WindDataRecord>();

    return (
        <div className="main-data">
            <h2>
                <b>
                    {waveEntries[0].height}m
                    - {fromDegreesToCardinal(waveEntries[0].direction)} - {waveEntries[0].period}s
                    ({waveEntries[0].direction}º)
                </b>
            </h2>
            <h4>{waveEntries[0].energy}kJ</h4>
            <small>{waveEntries[0].date}</small>
            <small>({waveEntries[0].temperature}ºC)</small>
            {lastWindEntry && <p>Viento: <a href="#"
                                            onClick={() => console.log('Navigate to wind station page')}>{lastWindEntry.windAverageSpeed}
                - {lastWindEntry.windDirectionString}</a></p>}
            {!lastWindEntry && <p>Datos de viento no disponibles para la estación</p>}
        </div>
    );

};