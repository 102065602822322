import './App.css';
import {Fragment} from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import AppMenu from './AppMenu';
import SurfinityNavigation from './SurfinityNavigation';

const App = (props: any) => {

    return (
        <Fragment>
            <AppMenu />
            <SurfinityNavigation />
        </Fragment>
    );
};

export default App;
