import WaveOverview from './views/WaveOverview';
import ForecastView from './views/ForecastView';
import AddAlertView from './views/AddAlertView';
import AboutView from './views/AboutView';
import {WaveStation} from './views/WaveStation';
import {ErrorView} from './views/ErrorView';
import AlertChannelsView from './views/AlertChannelsView';
import {Fragment} from 'react';
import MarketView from './views/MarketView';
import DashboardView from './views/DashboardView';
import {WindOverview} from './views/WindOverview';
import {Route, Routes} from 'react-router-dom';
import BeachCamsView from './views/BeachCamsView';
import WindTodayView from './views/WindTodayView';

const SurfinityNavigation = () => {
    return (
        <Fragment>
            <Routes>
                <Route exact path={'/home'} element={<DashboardView/>}/>
                <Route exact path={'/wave'} element={<WaveOverview/>}/>
                <Route exact path={'/wind'} element={<WindOverview/>}/>
                <Route exact path={'/forecast'} element={<ForecastView/>}/>
                <Route exact path={'/wind-today'} element={<WindTodayView/>}/>
                <Route exact path={'/beach-cams'} element={<BeachCamsView/>}/>
                <Route exact path={'/palagua'} element={<AddAlertView/>}/>
                <Route exact path={'/alert-channels'} element={<AlertChannelsView/>}/>
                <Route exact path={'/market'} element={<MarketView/>}/>
                <Route exact path={'/about'} element={<AboutView/>}/>
                <Route exact path={'/wave-station/:stationId'} element={<WaveStation/>}/>
                <Route exact path={'/'} element={<DashboardView/>}/>
                <Route path={'*'} element={<ErrorView/>}/>
            </Routes>
        </Fragment>
    );
};

export default SurfinityNavigation;