import {useEffect, useState} from 'react';
import * as moment from 'moment';
import {
    Area,
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';

import {fromDegreesToCardinal} from '../util/SurfinityUtils';
import ForecastDataRecord from '../domain/ForecastDataRecord';
import {ForecastDataService} from '../providers/forecast-data-service';

import './WindTodayView.css';

const ForecastView = () => {

    const [forecastData, setForecastData] = useState<ForecastDataRecord[]>([]);

    const calculateWindGust = (windSpeed: number, windDirection: string): {windSpeed: number, windGust: number, windMin: number} => {
        switch (windDirection) {
            case 'SSE': {
                return {windSpeed: windSpeed*0.8, windGust: windSpeed*1.5, windMin: windSpeed*0.5};
            }
            case 'SW':
            case 'WSW':
            case 'W':
            case 'WNW':
            case 'NW':
            case 'SSW': {
                return {windSpeed: windSpeed, windGust: windSpeed*1.8, windMin: windSpeed*0.5} ;
            }
            default: {
                return {windSpeed: windSpeed, windGust: (windSpeed+3) >= windSpeed*1.4 ? (windSpeed+4) : windSpeed*1.4, windMin: windSpeed*0.7};
            }
        }
    };

    useEffect(() => {
        ForecastDataService.findWindToday().then(response => {
            const dataWithGustInfo = response.data.map((record: ForecastDataRecord) => {
                const modifiedData = calculateWindGust(record.windSpeed, fromDegreesToCardinal(record.windDirection));
                record.windSpeed = Math.round(modifiedData.windSpeed);
                record.windGust = Math.round(modifiedData.windGust);
                record.windMin = Math.round(modifiedData.windMin);
               return record;
            });
            setForecastData(dataWithGustInfo);
        });
    }, []);

    const _resolveEnergyClassName = (energy: number) => {
        if (energy < 150) return 'tooltip-low';
        if (energy >= 150 && energy < 200) return 'tooltip-medium';
        if (energy >= 200) return 'tooltip-high';
    };

    const _resolveWindSpeedClassName = (windSpeed: number) => {
        if (windSpeed < 12) return 'tooltip-low';
        if (windSpeed >= 12 && windSpeed < 16) return 'tooltip-medium';
        if (windSpeed >= 16) return 'tooltip-high';
    };

    const CustomTooltip = ({active, payload}) => {
        if (active && payload && payload.length) {
            return (
                <ul className={'tooltip-container'}>
                    <li className={_resolveWindSpeedClassName(payload[0].payload.windSpeed)}>{payload[0].payload.windSpeed}kts</li>
                    <li className={_resolveWindSpeedClassName(payload[0].payload.windSpeed)}>({payload[0].payload.windMin} - {payload[0].payload.windGust}kts)</li>
                    <li
                        className={'tooltip-direction'}>{payload[0].payload.windDirection}º {fromDegreesToCardinal(payload[0].payload.windDirection)}</li>
                    <li className={'tooltip-height'}>{payload[0].payload.waveHeight}m</li>
                    <li
                        className={'tooltip-direction'}>{payload[0].payload.waveDirection}º {fromDegreesToCardinal(payload[0].payload.waveDirection)}</li>
                    <li
                        className={_resolveEnergyClassName(payload[0].payload.waveEnergy)}>{payload[0].payload.waveEnergy}kj</li>
                    <li className={'tooltip-date'}><i>{moment(payload[0].payload.date).format('ddd HH:mm')}h</i></li>
                </ul>
            );
        }

        return null;
    };

    // @ts-ignore
    const _renderForecastData = () => {
        return (
            <ResponsiveContainer width="100%" height="80%">
                <ComposedChart data={forecastData} margin={{
                    top: 0,
                    right: 20,
                    left: -30,
                    bottom: 0,
                }}>
                    <CartesianGrid strokeDasharray="4 1 2"/>
                    <XAxis xAxisId={0} dataKey={'date'} tickFormatter={(date) => moment(date).format('ddd HH:mm')}/>
                    <XAxis xAxisId={1} dataKey={'windDirection'} orientation={'top'} stroke={'#4268BB'} tickFormatter={(direction) => fromDegreesToCardinal(direction)}/>
                    {/*<XAxis xAxisId={2} dataKey={'waveDirection'} stroke={'#48BB65'} tickFormatter={(direction) => fromDegreesToCardinal(direction)}/>*/}
                    <YAxis yAxisId={3} dataKey={'windGust'} stroke={'#4268BB'}/>
                    {/*<YAxis yAxisId={4} dataKey={'waveHeight'} orientation={'right'} stroke={'#48BB65'}/>*/}
                    <Tooltip content={<CustomTooltip active={undefined} payload={undefined}/>}/>
                    <ReferenceLine yAxisId={3} y={14} stroke={'#4268BB'}/>
                    <Line yAxisId={3} name={'Viento (kts)'} type={'monotone'} dataKey={'windSpeed'} stroke={'#b1b8c4'} strokeWidth={6} dot={true}/>
                    <Area yAxisId={3} name={'Racha (kts)'} type={'monotone'} dataKey={'windGust'} fill={'rgba(146,150,146,0.53)'} stroke={'#2dc43f'} strokeWidth={1} dot={false}/>
                    <Area yAxisId={3} name={'Mínima (kts)'} type={'monotone'} dataKey={'windMin'} fill={'#000000'} stroke={'#ce220f'} strokeWidth={1} dot={false}/>
                    <Legend verticalAlign="bottom" height={10} align={'center'}/>
                </ComposedChart>
            </ResponsiveContainer>
        );
    };

    return (
        <div className={'wave-overview'}>
            {forecastData && _renderForecastData()}
        </div>
    );
};

export default ForecastView;