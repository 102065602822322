import './AlertChannelsView.css';
import {Button, Divider} from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';


const AlertChannelsView = () => {
    return (
        <div className={'alert-channels-view'}>
            <p>Si quieres recibir alertas, tienes diferentes canales de Telegram a los que te puedes suscribir.</p>
            <div className={'channel-buttons-container'}>
                <Button fullWidth variant={'outlined'} color={'primary'} size={'large'}
                        startIcon={<TelegramIcon/>}>
                    <a href={'https://t.me/surfinity_wave'} target={'_blank'} rel="noreferrer">Oleaje</a></Button>
                <Button fullWidth variant={'outlined'} color={'primary'} size={'large'} startIcon={<TelegramIcon/>}>
                    <a href={'https://t.me/surfinity_wind'} target={'_blank'} rel="noreferrer">Viento</a></Button>
                <Button fullWidth variant={'outlined'} color={'primary'} size={'large'} startIcon={<TelegramIcon/>}>
                    <a href={'https://t.me/surfinity_wave_fc'} target={'_blank'} rel="noreferrer">Partes de
                        oleaje</a></Button>

                <Button fullWidth variant={'outlined'} color={'primary'} size={'large'} startIcon={<TelegramIcon/>}>
                    <a href={'https://t.me/surfinity_wind_fc'} target={'_blank'} rel="noreferrer">Partes de
                        viento</a></Button>
                <Divider/>
                <Button fullWidth variant={'outlined'} color={'primary'} size={'large'}
                        startIcon={<TelegramIcon/>}>
                    <a href={'https://t.me/surfinity'} target={'_blank'} rel="noreferrer">Surfinity (canal general)</a></Button>
            </div>
            <p>Recuerda que en cualquier momento puedes salir y entrar al canal, o activar/silenciar las
                notificaciones.</p>
        </div>
    );
};

export default AlertChannelsView;