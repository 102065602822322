import axios, {AxiosInstance} from 'axios';

export const ApiClient = (): AxiosInstance => {
    const apiHttpClient = axios.create();
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    apiHttpClient.defaults.withCredentials = true;

    apiHttpClient.interceptors.request.use(request => {
        return request;
    });

    apiHttpClient.interceptors.response.use(response => {
        return response;
    });

    return apiHttpClient;
};

export default ApiClient;