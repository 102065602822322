import {
    Alert,
    AppBar,
    Avatar,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Snackbar,
    SwipeableDrawer,
    Toolbar,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import {NavLink, useNavigate} from 'react-router-dom';
import WavesIcon from '@mui/icons-material/Waves';
import AirIcon from '@mui/icons-material/Air';
import InfoIcon from '@mui/icons-material/Info';
import FlagIcon from '@mui/icons-material/Flag';
import TelegramIcon from '@mui/icons-material/Telegram';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import {Fragment, useState} from 'react';

import icon from './assets/icon.png';
import palagua from './assets/img/vadewind.png';
import headerLogo from './assets/img/surfinity_header.svg';
import {Videocam} from '@mui/icons-material';

const AppMenu = () => {
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>('');

    const navigate = useNavigate();

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const _handleUnderConstructionAlert = () => {
        setSnackbarMessage('En desarrollo...');
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setSnackbarMessage('');
    };

    return (
        <Fragment>
            <AppBar position="sticky">
                <Toolbar className={'app-toolbar'} sx={{
                    justifyContent: 'space-between'
                }}>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{mr: 2}}
                        onClick={toggleMenu}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <IconButton
                        size="large"
                        aria-label="surfinity"
                        color="inherit"
                        onClick={() => navigate('/home')}
                    >
                        <img src={headerLogo} height={30} alt={'header logo'}/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <SwipeableDrawer
                anchor={'left'}
                open={menuOpen}
                onClose={toggleMenu}
                onOpen={toggleMenu}
            >
                <List>
                    <ListItem>
                        <ListItemButton>
                            <IconButton
                                size="large"
                                aria-label="surfinity"
                                color="inherit"
                            >
                                <img alt={'Surfinity Mareny Blau Edition'} src={icon} width={50}/>
                            </IconButton>
                        </ListItemButton>
                    </ListItem>
                    <Divider/>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <HomeIcon/>
                            </ListItemIcon>
                            <NavLink underline={'none'} to={'/home'} onClick={toggleMenu}><ListItemText
                                primary="Inicio"/></NavLink>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <AirIcon />
                            </ListItemIcon>
                            {/*<NavLink underline={'none'} to={'/add-session'} onClick={toggleMenu}><ListItemText*/}
                            <NavLink underline={'none'} to={'/wind-today'} onClick={toggleMenu}><ListItemText
                                primary="Parte hoy"/></NavLink>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <WavesIcon/>
                            </ListItemIcon>
                            <NavLink underline={'none'} to={'/wave'} onClick={toggleMenu}><ListItemText
                                primary="Oleaje tiempo real"/></NavLink>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <AirIcon/>
                            </ListItemIcon>
                            <NavLink underline={'none'} to={'/forecast'} onClick={toggleMenu}><ListItemText
                                primary="Parte olas y viento"/></NavLink>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <FlagIcon />
                            </ListItemIcon>
                            {/*<NavLink underline={'none'} to={'/add-session'} onClick={toggleMenu}><ListItemText*/}
                            <NavLink underline={'none'} to={'/wind'} onClick={toggleMenu}><ListItemText
                                primary="Viento Mareny Blau"/></NavLink>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <Videocam/>
                            </ListItemIcon>
                            <NavLink underline={'none'} to={'/beach-cams'} onClick={toggleMenu}><ListItemText
                                primary="Beach cams"/></NavLink>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <Avatar variant={'circular'} alt={'Pal agua!'} src={palagua} style={{
                                    width: 25,
                                    height: 25
                                }} />
                            </ListItemIcon>
                            <a href={'https://vadewind.com/app/preview/vadewind.php'} target={'_blank'} rel={'noreferrer'}><ListItemText
                                primary="VaDeWind"/></a>
                        </ListItemButton>
                    </ListItem>
                    <Divider/>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <TelegramIcon/>
                            </ListItemIcon>
                            <NavLink underline={'none'} to={'/alert-channels'} onClick={toggleMenu}><ListItemText
                                primary="Canales de alertas"/></NavLink>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton disabled={true}>
                            <ListItemIcon>
                                <ShoppingBasketIcon/>
                            </ListItemIcon>
                            <NavLink underline={'none'} to={'#'} onClick={_handleUnderConstructionAlert}><ListItemText
                                primary="Compra/Venta"/></NavLink>
                        </ListItemButton>
                    </ListItem>
                    <Divider/>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <InfoIcon/>
                            </ListItemIcon>
                            <NavLink underline={'none'} to={'/about'} onClick={toggleMenu}><ListItemText
                                primary="Acerca de..."/></NavLink>
                        </ListItemButton>
                    </ListItem>
                </List>
            </SwipeableDrawer>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}>
                <Alert onClose={handleSnackbarClose} severity="warning" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

        </Fragment>
    );
};

export default AppMenu;
