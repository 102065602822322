import './DataCard.css';

interface WindDataCardProps {
    value: number;
    unit: string;
    direction: string;
    energy?: number;
    period?: number;
    temperature?: number;
    footer: string;
    onClick?: any;
}

const DataCard = (props: WindDataCardProps) => {

    const _resolveEnergyClassname = () => {
        if (props.energy <= 180) return 'energy-very-low';
        if (props.energy > 180 && props.energy < 200) return 'energy-low';
        if (props.energy >= 200) return 'energy-high';
    };

    return (
        <div className={'data-card'} onClick={props.onClick}>
            <div className={'data'}>
                <span className={'value'}>{props.value}
                    <span className={'value-unit'}>{props.unit}</span>
                    <span className={'direction'}>{props.direction}</span>
                </span>
                {props.energy &&
                <span className={`${_resolveEnergyClassname()} energy`}>{props.period}s / {props.energy}kj
                    <span className={`temperature ${props.temperature < 10 ? 'temperature-low' : ''} temperature-water`}> - ({props.temperature}ºC)</span></span>}
                {props.temperature && !props.energy && <span
                    className={`temperature ${props.temperature < 10 ? 'temperature-low' : ''}`}> ({props.temperature}ºC) </span>}
            </div>
            <div className={'station-data'}>
                <span className={'station-name'}>{props.footer}</span>
            </div>
        </div>
    );
};

export default DataCard;