import DataCard from '../components/DataCard';

import './DashboardView.css';
import {useEffect, useState} from 'react';
import WindDataRecord from '../domain/WindDataRecord';
import WaveDataRecord from '../domain/WaveDataRecord';
import {WindDataService} from '../providers/wind-data-service';
import {WaveDataService} from '../providers/wave-data-service';
import {fromDegreesToCardinal} from '../util/SurfinityUtils';
import {
    Area,
    AreaChart,
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    ReferenceLine,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from 'recharts';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {LogService} from '../providers/log-service';

export const FETCH_INTERVAL: number = 120000;

const DashboardView = () => {
    const [windData, setWindData] = useState<WindDataRecord[]>([]);
    const [waveData, setWaveData] = useState<WaveDataRecord[]>([]);

    const navigate = useNavigate();

    const fetchData = () => {
        LogService.debug('Updating data...');
        WindDataService.findLastWindEntriesFromSource(1, 12).then(response => {
            setWindData(response.data);
        });
        WaveDataService.findLastWaveEntriesFromSource(1, 12).then(response => {
            setWaveData(response.data);
        });
        LogService.debug('Data updated');
    };

    useEffect(() => {
        fetchData();
        setInterval(fetchData, FETCH_INTERVAL);
    }, []);

    return (
        <div className={'dashboard-view'}>
            <div className={'data-cards-row'}>
                {windData.length > 0 &&
                <DataCard onClick={() => navigate('/wind')} value={windData[0].windCurrentSpeed}
                          direction={windData[0].windDirectionString}
                          temperature={windData[0].temperature}
                          footer={'Ver Mareny Blau'} unit={'kts'}/>}
                <span>&nbsp;</span>
                {waveData.length > 0 &&
                <DataCard onClick={() => navigate('/wave')} value={waveData[0].height}
                          direction={`${fromDegreesToCardinal(waveData[0].direction)} ${waveData[0].direction}º`}
                          energy={waveData[0].energy}
                          period={waveData[0].period}
                          footer={'Ver boya de Valencia'} unit={'m'}
                          temperature={waveData[0].temperature}
                />}
            </div>
            <div className={'wind-data-chart'}>
                {windData.length > 0 &&
                <ResponsiveContainer width="100%" height="80%">
                    <ComposedChart data={[...windData].reverse()} margin={{
                        top: -10,
                        right: 20,
                        left: -20,
                        bottom: 0,
                    }}>
                        <CartesianGrid strokeDasharray="3 5"/>
                        <XAxis xAxisId={0} dataKey={'creationDate'}
                               tickFormatter={(date) => moment(date).format('HH:mm')}/>
                        <XAxis xAxisId={1} dataKey={'windDirectionString'} orientation={'top'} stroke="#4268BB"/>
                        <YAxis dataKey={'windCurrentSpeed'} orientation={'left'} stroke="#4268BB"/>
                        <ReferenceLine y={14} stroke="red"/>
                        <Line name={'Viento (kts)'} type="monotone" dataKey="windCurrentSpeed" stroke="#4268BB"
                              strokeWidth={3}
                              dot={false}/>
                        <Legend verticalAlign="bottom" height={15} align={'center'}/>
                    </ComposedChart>
                </ResponsiveContainer>
                }
            </div>
            <div className={'wave-data-chart'}>
                <ResponsiveContainer width="100%" height="65%">
                    <AreaChart data={[...waveData].reverse()} margin={{
                        top: -5,
                        right: 20,
                        left: -20,
                        bottom: 5,
                    }}>
                        <CartesianGrid strokeDasharray="5"/>
                        <XAxis xAxisId={0} dataKey={'date'} tickFormatter={(date) => moment(date).format('HH:mm')}/>
                        <XAxis xAxisId={1} dataKey={'direction'} orientation={'top'}
                               tickFormatter={(direction) => `${fromDegreesToCardinal(direction)} ${direction}º`}
                               stroke="#4268BB"/>
                        <YAxis dataKey={'height'} name={'Altura'} stroke="#4268BB"/>
                        <Area name={'Altura (m)'} type="monotone" dataKey="height" stroke="#4268BB" fill="#3366CC"/>
                        <Legend verticalAlign="bottom" height={20} align={'center'}/>
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default DashboardView;
