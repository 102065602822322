import './WaveOverviewChart.css';
import {useEffect, useState} from 'react';
import {WaveDataService} from '../providers/wave-data-service';
import WaveDataRecord from '../domain/WaveDataRecord';
import {Area, ComposedChart, Legend, Line, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

import * as moment from 'moment';
import {fromDegreesToCardinal} from '../util/SurfinityUtils';
import {FETCH_INTERVAL} from './DashboardView';

const WaveOverviewChart = () => {
        const [waveData, setWaveData] = useState<WaveDataRecord[]>([]);

        const fetchData = () => {
            WaveDataService.findLastWaveEntriesFromSource(1, 30).then((response) => {
                setWaveData(response.data.reverse());
            }).catch(error => {
                console.log(error);
            });
        };

        useEffect(() => {
            fetchData();
            setInterval(fetchData, FETCH_INTERVAL);
        }, []);

        const _resolveEnergyClassName = (energy: number) => {
            if (energy < 150) return 'tooltip-energy-low';
            if (energy >= 150 && energy < 200) return 'tooltip-energy-medium';
            if (energy >= 200) return 'tooltip-energy-high';
        };

        const CustomTooltip = ({active, payload}) => {
            if (active && payload && payload.length) {
                return (
                    <ul className={'tooltip-container'}>
                        <li className={'tooltip-height'}>{payload[0].value}m</li>
                        <li className={'tooltip-direction'}>{payload[0].payload.direction}º {fromDegreesToCardinal(payload[0].payload.direction)}</li>
                        <li>{payload[0].payload.period}s</li>
                        <li className={_resolveEnergyClassName(payload[0].payload.energy)}>{payload[0].payload.energy}kj</li>
                        <li className={'tooltip-date'}><i>{moment(payload[0].payload.date).format('HH:mm')}h</i></li>
                    </ul>
                );
            }

            return null;
        };

        const _renderWaveData = () => {
            return (
                <ResponsiveContainer width="100%" height="70%">
                    <ComposedChart data={waveData} margin={{
                        top: 0,
                        right: 0,
                        left: -20,
                        bottom: 15,
                    }}>
                        {/*<CartesianGrid strokeDasharray="5"/>*/}
                        <XAxis xAxisId={0} dataKey={'date'} tickFormatter={(date) => `${moment(date).format('HH')}h`}/>
                        <XAxis xAxisId={1} dataKey={'direction'} orientation={'top'} stroke="#4268BB"
                               tickFormatter={(direction) => `${fromDegreesToCardinal(direction)} ${direction}º`}/>
                        <YAxis yAxisId={2} dataKey={'energy'} stroke="#EC871B" orientation={'right'}/>
                        <YAxis dataKey={'height'} name={'Altura'} stroke="#4268BB"/>
                        <Tooltip content={<CustomTooltip active={undefined} payload={undefined}/>}/>
                        <ReferenceLine yAxisId={2} y={200} stroke="#EC871B"/>
                        <ReferenceLine y={0.7} stroke="#4268BB"/>
                        <Area name={'Altura (m)'} type="monotone" dataKey="height" stroke="#4268BB" fill="#3366CC"/>
                        <Line yAxisId={2} name={'Energía (kj)'} type="monotone" dataKey="energy" dot={false} stroke="#EC871B" unit={'kj'}/>
                        <Legend verticalAlign="bottom" height={20} align={'center'}/>
                    </ComposedChart>
                </ResponsiveContainer>
            );
        };

        return (
            <div className={'wave-overview'}>
                {_renderWaveData()}
            </div>
        );
    }
;

export default WaveOverviewChart;
